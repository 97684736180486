<template>
  <div
    class="grow flex flex-col"
    :class="{ 'bg-neutral/80': !withoutMockedPhone }">
    <div
      v-if="!isGuest && !isMocked"
      class="sticky top-0 w-full py-1 px-4 bg-warning text-center z-20">
      You are previewing the Instabooth app for guests. When you are done click
      <NuxtLink
        :to="localeRoute({ name: 'events-eventId' })"
        class="kbd kbd-sm px-2 bg-opacity-70"
        >here</NuxtLink
      >
      to go back to Instabooth management.
    </div>

    <div class="relative grow flex flex-col" v-if="withoutMockedPhone">
      <div
        v-if="isMocked"
        class="fixed h-7 z-20 w-full top-0 bg-base-200"></div>
      <BoothHeader></BoothHeader>
      <div class="flex-1 flex flex-col container mx-auto p-6 pt-8">
        <slot />
      </div>
      <BoothFooter></BoothFooter>
    </div>
    <div v-else class="flex-1 mx-auto my-4">
      <div class="mockup-phone shadow-lg">
        <div class="camera"></div>
        <div class="display">
          <div
            class="artboard artboard-demo"
            :class="{
              'phone-2': height < 812,
              'phone-4': height >= 812,
              'phone-5': height >= 896,
            }">
            <iframe
              class="h-full w-full border-0"
              frameborder="none"
              :src="`${url.pathname}?mocked=true`">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useWindowSize } from "@vueuse/core";

  const sessionStore = useSessionStore();
  const localeRoute = useLocaleRoute();
  const isGuest = ref(true);
  const url = useRequestURL();
  const isMocked = ref(url.search.includes("mocked"));
  const { height, width } = useWindowSize();
  const isWide = computed(() => width.value > 500);

  const withoutMockedPhone = computed(
    () => isGuest.value || isMocked.value || !isWide.value
  );

  onMounted(() => {
    isGuest.value =
      sessionStore.auth?.currentUser?.providerData[0]?.providerId != "password";
  });
</script>
